import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './designedAndBuilt.style';

const DesignedAndBuilt = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        designAndBuilt {
          image {
            publicURL
          }
          title
          slogan
          description
          link
        }
      }
    }
  `);
  const {
    image,
    title,
    slogan,
    description,
    link
  } = data.appModernJson.designAndBuilt;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <SectionWrapper>
        <Container>
          <ContentWrapper>
            <div className="content">
              <Heading as="h5" content={slogan} />
              <Heading content={title} />
              <Text content={description} />
            </div>
            <div className="image">
              <Image src={image.publicURL} alt="Built Logo" />
            </div>
          </ContentWrapper>
        </Container>
      </SectionWrapper>
    </a>
  );
};

export default DesignedAndBuilt;
