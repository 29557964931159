import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../Particle';
import DashboardWrapper, { DashboardObject } from './dashboard.style';
import { Icon } from 'react-icons-kit';
import { apple } from 'react-icons-kit/fa/apple';
import { windows } from 'react-icons-kit/fa/windows';
import { android } from 'react-icons-kit/fa/android';

import DashboardObject1 from 'common/src/assets/image/appModern/dashboard.png';

const DashboardSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <a target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }} href="https://apps.apple.com/vn/app/fluxbuilder/id1500753204?mt=12">
        <Button
          {...btnStyle}
          icon={<Icon icon={apple} />}
          iconPosition="left"
          title="Appstore (MacOS)"
        />
      </a>

      <a target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }} href="https://github.com/inspireui/fluxbuilder">
        <Button
          {...btnStyle}
          icon={<Icon icon={windows} />}
          iconPosition="left"
          title="Github (Window OS)"
        />
      </a>

      {/* <a target="_blank" rel="noopener noreferrer" href="	https://play.google.com/store/apps/details?id=com.inspireui.fluxstore">
        <Button
          {...btnStyle}
          icon={<Icon icon={android} />}
          iconPosition="left"
          title="Google Play"
        />
      </a> */}

    </Fragment>
  );
  return (
    <DashboardWrapper id="banner_section">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <Heading className="subtitle" as="h5" content="NEW APP TOOL" />
            <FeatureBlock
              title={
                <Heading
                  content="Extra powerful builder."
                  {...title}
                />
              }
              description={
                <Text
                  content="We also release the FluxBuilder product that are innovative, differentiated with a superb User Experience, fully accessible through mobile and tablet devices to control the app interface and features supper easily, also get push notifications. This product is free for useage for the products purchased."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
            <div style={{marginTop: '15px', fontSize: '13px', opacity: 0.6}}>
              More detail via website <a target="_blank" rel="noopener noreferrer" href="https://fluxbuilder.com">https://fluxbuilder.com</a>
            </div>
          </Box>
        </Box>
      </Container>
      <DashboardObject>
        <div className="dashboardWrapper">
        < video loop={true} preload={"auto"} playsInline muted autoPlay  width="100%" src="https://trello.com/1/cards/5e5e4aab5131e03af2ba2a1a/attachments/5e5e4aab5131e03af2ba2a1b/download/fluxbuilderpro.mp4" />
        </div>
        
      </DashboardObject>
     
    </DashboardWrapper>
  );
};

DashboardSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
};

DashboardSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '30px', '30px', '30px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '15px', '15px', '20px', '25px'],
    lineHeight: '1.3',
    maxWidth: ['100%', '400px'],
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.85',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    marginRight: '15px',
    colors: 'secondaryWithBg',
  },
};

export default DashboardSection;
